import { unparse, parse } from "papaparse";

const options = {
  encoding: "windows-1250",
  skipEmptyLines: true,
  delimiter: ";",
};

export const generateCsv = function (headers, items = new Array({})) {
  return unparse({ fields: headers, data: items }, options);
};

export const importFromCsv = function (file, importCsvCallback) {
  parse(file, {
    ...options,
    complete: (results) => {
      importCsvCallback(results.errors.length > 0 ? [] : results.data);
    },
  });
};

export const downloadCsvFile = function (data, fileName) {
  downloadFile("\ufeff" + data, fileName, "text/csv");
};

export const downloadFile = function (
  data,
  fileName = "plik.txt",
  mimeType = "text/plain",
  extension
) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([data], { type: mimeType });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, fileName);
    return;
  }
  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.

  const fileNameWithExtension = extension
    ? fileName + `.${extension}`
    : fileName;

  const object = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = object;
  link.name = fileNameWithExtension;
  link.download = fileNameWithExtension;
  document.body.appendChild(link);
  link.setAttribute("style", "display: none");
  link.click();
  window.URL.revokeObjectURL(object);
  link.remove();
};
